import request from '@/utils/request';

export function index(params) {
    return request({
        url: '/admin',
        method: 'get',
        params
    });
}

export function store(data) {
    return request({
        url: '/admin',
        method: 'post',
        data
    });
}

export function show(id) {
    return request({
        url: `/admin/${id}`,
        method: 'get'
    });
}

export function update({ id, data }) {
    return request({
        url: `/admin/${id}`,
        method: 'put',
        data
    });
}

export function destroy(id) {
    return request({
        url: `/admin/${id}`,
        method: 'delete'
    });
}