import layout from '@/layout/index.vue';

export default {
  path: '/receiptSample',
  component: layout,
  meta: {
    category: 'system',
    allow: 1,
  },
  children: [
    {
      path: '/receiptSample',
      name: 'receiptSample',
      meta: {
        title: '收據模板管理',
        icon: 'cilColorBorder',
        allow: 1,
      },
      component: () => import('@/views/receipt/receiptSample.vue')
    },
    {
      path: '/receiptSampleForm/:id',
      name: 'receiptSampleForm',
      meta: {
        title: '編輯收據模板',
        icon: 'cilColorBorder',
        allow: 1,
        hide: true
      },
      component: () => import('@/views/receipt/receiptSampleForm.vue')
    }
  ]
}
