import resource from '@/api/resource.js';
const api = new resource('orderDetail');

export default {
    namespaced: true,
    state: {
        info: {},
        list: [],
        unComment: [],
        tableSetting: {
            loading: true,
            searching: false,
            pageSetting: {
                total: 0,
                current: 1,
                pageSize: 10
            }
        },
        selectData: {
        },
        formData: {
        },
        showListId: null,
        showInfoId: null,
        showNextId: null
    },
    getters: {
    },
    mutations: {
        setList: (state, { name, data }) => {
            if (Object.keys(data).indexOf('list') >= 0 && data.list.length > 0) {
                data.list.forEach(function(item, index) {
                    data.list[index].loading    = false;
                    data.list[index].showStatus = false;
                    data.list[index].showList   = state.showListId == item.id;
                    data.list[index].showInfo   = state.showInfoId == item.order_detail[0].cases_id;
                    item.order_detail.forEach(function(value, key) {
                        data.list[index].order_detail[key].formData   = { order_id: item.order_id, cases_id: value.cases_id, remark: null, file_name: null, file: [] };
                        data.list[index].order_detail[key].showStatus = false;
                        data.list[index].order_detail[key].showInfo   = state.showInfoId == value.cases_id;
                    });
                });
            }

            state['list'] = data.list || [];
            state['tableSetting'].pageSetting.total = data.total || 0;

            state['showListId'] = null;
            state['showInfoId'] = null;
        },
        setUnComment: (state, { name, data }) => {
            state['unComment'] = data.list || [];
        },
        setInfo: (state, { name, data }) => {
            state['info'] = data || {};
        },
        setLoading: (state, { name, data }) => {
            state['tableSetting'].loading     = data;
            state['tableSetting'].searching   = data;
        },
        setTableLoading: (state, { name, index, data }) => {
            state['list'][index].loading = data;
        },
        setPageSetting: (state, { name, type, data }) => {
            state['tableSetting'].pageSetting[type] = data;
        },
        setSelectData: (state, { name, field, data }) => {
            state['selectData'][field] = data;
        },
        setSelectAllData: (state, { name, data }) => {
            state['selectData'] = data;
        },
        setFormData: (state, { name, data }) => {
            state['formData'] = data;
        },
        setFormDataPush: (state, { name, field, data }) => {
            state['formData'][field].push(data);
        },
        setShowListId: (state, { name, data }) => {
            state['showListId'] = data;
        },
        setShowInfoId: (state, { name, data }) => {
            state['showInfoId'] = data;
        },
        setShowNextId: (state, { name, data }) => {
            state['showNextId'] = data;
        }
    },
    actions: {
        getList({ state, commit }) {
            return new Promise((resolve, reject) => {
                let selectFrom  = state.selectData
                let pageSetting = state.tableSetting.pageSetting
                api.index({ selectFrom, pageSetting }).then(response => {
                    if (response.status) {
                        commit('setList', { name: 'orderDetail', data: response.data });
                        commit('setLoading', { name: 'orderDetail', data: false });
                    }
                    resolve(response);
                }).catch(error => {
                    reject(error);
                });
            });
        },
        getInfo({ state, commit }, id) {
            return new Promise((resolve, reject) => {
                api.show(id).then(response => {
                    if (response.status) {
                        commit('setInfo', { name: 'orderDetail', data: response.data });
                    }
                    resolve(response);
                }).catch(error => {
                    reject(error);
                });
            });
        },
        insert({}, data) {
            return new Promise((resolve, reject) => {
                api.store(data).then(response => {
                    resolve(response.status);
                }).catch(error => {
                    reject(error);
                });
            });
        },
        update({ commit }, { id, data }) {
            return new Promise((resolve, reject) => {
                api.update({ id, data }).then(response => {
                    resolve(response.status);
                }).catch(error => {
                    reject(error);
                });
            });
        },
        status({ commit }, { id, data }) {
            return new Promise((resolve, reject) => {
                api.status({ id, data }).then(response => {
                    if (response.data.hasNext == false && response.data.id)
                        commit('setShowNextId', { name: 'orderDetail', data: response.data.id });
                        
                    resolve(response.status);
                }).catch(error => {
                    reject(error);
                });
            });
        },
        delete({ commit }, id) {
            return new Promise((resolve, reject) => {
                api.destroy(id).then(response => {
                    resolve(response.status);
                }).catch(error => {
                    reject(error);
                });
            });
        },
        select({ rootState, commit}) {
            return new Promise((resolve, reject) => {
                if (rootState.select.orderDetail.need)
                    resolve(true);

                api.select().then(response => {
                    if (response.status) {
                        commit('select/setList', { name: 'orderDetail', data: response.data }, { root: true });
                    }
                    resolve(response.status);
                }).catch(error => {
                    reject(error);
                });
            });
        },
        unComment({ rootState, commit }) {
            return new Promise((resolve, reject) => {
                api.unComment().then(response => {
                    if (response.status) {
                        commit('setUnComment', { name: 'unComment', data: response.data });
                    }
                    resolve(response.status);
                }).catch(error => {
                    reject(error);
                });
            });
        },
    }
}
