import layout from '@/layout/index.vue';

export default {
    path: '/schedule',
    component: layout,
    meta: {
        title: '班表',
        // category: 'resource',
        category: 'stInfo',
        icon: 'cilChart',
        allow: 7,
    },
    children: [
        {
            path: '/schedule',
            name: 'schedule',
            meta: {
                title: '班表',
                icon: 'cilCalendar',
                allow: 7,
            },
            component: () => import('@/views/schedule/schedule.vue')
        }
    ]
}
