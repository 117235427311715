import layout from '@/layout/index.vue';

export default {
  path: '/mailSetting',
  component: layout,
  meta: {
    category: 'system',
    allow: 3,
  },
  children: [
    {
      path: '/mailSetting',
      name: 'mailSetting',
      meta: {
        title: '信箱設定',
        icon: 'cilSettings',
        allow: 3,
      },
      component: () => import('@/views/mailSetting/mailSetting.vue')
    }
  ]
}
