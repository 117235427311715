import router from './router';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import { isLogged } from '@/utils/auth';
import getPageTitle from '@/utils/pageTitle';
import store from './store';

NProgress.configure({ showSpinner: false });

const whiteList   = ['login'];
const outsideList = ['book', 'booking', 'error401', 'error404', 'error500', 'survey'];

router.beforeEach(async (to, from, next) => {
    NProgress.start();

    document.title = getPageTitle(to.title);

    const logged = isLogged();

    if (outsideList.indexOf(to.matched[0] ? to.matched[0].name : '') !== -1)
    {
        next();
    }
    else if (whiteList.indexOf(to.matched[0] ? to.matched[0].name : '') !== -1)
    {
        if (logged) {
            next({path: '/'});
            NProgress.done();
        } else {
            next();
        }
    }
    else if (!logged)
    {
        next(`/login?redirect=${to.path}`);
        NProgress.done();
    }
    else
    {
        const hasRoles = store.state.auth.roles && store.state.auth.roles.length > 0;
        if (hasRoles)
        {
            if (to.redirect) {
                next(to.redirect);
            } else {
                next();
            }
        }
        else
        {
            try {
                const data = await store.dispatch('auth/info');
                if (data.status)
                {
                    const roles = store.state.auth.roles;
                    const type  = store.state.auth.user.type;
                    await store.dispatch('router/generateRoutes', { roles, type }).then(() => {
                        store.state.router.addRouters.forEach((item) => {
                            router.addRoute(item);
                        });
                    });
                    next({...to, replace: true});
                }
                else
                {
                    await store.dispatch('auth/logout');
                    next(`/login?redirect=${to.path}`);
                    NProgress.done();
                }
            } catch (error) {
                await store.dispatch('auth/logout');
                next(`/login?redirect=${to.path}`);
                NProgress.done();
            }
        }
    }
})

router.afterEach(() => {
    NProgress.done();
})