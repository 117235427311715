import store from './store';

export default (app) => {
	app.directive('allow', {
		mounted(el, binding, vnode, prevVnode) {
			let userType = store.state.auth.user.type || '';
			let nowType  = binding.value;
			if ((userType & nowType) == 0)
				el.parentNode.removeChild(el);
				//vnode.elm.parentElement.removeChild(vnode.elm)
		}
		// const type = vnode.context;
		// console.log(type)
		// const roles = vnode.context.$store.state.user.roles;
		// if (!roles.includes('*') && !roles.includes(binding.value.toString()))
		//     el.parentNode.removeChild(el);
	});
}