import request from '@/utils/request';

export function index(params) {
    return request({
        url: '/diagnosis',
        method: 'get',
        params
    });
}

export function store(data) {
    return request({
        url: '/diagnosis',
        method: 'post',
        data
    });
}

export function destroy(id) {
    return request({
        url: `/diagnosis/${id}`,
        method: 'delete'
    });
}

export function flag({ id, data }) {
    return request({
        url: `/diagnosis/flag/${id}`,
        method: 'put',
        data
    });
}

export function select() {
    return request({
        url: '/diagnosis/select',
        method: 'get'
    });
}