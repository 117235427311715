import resource from '@/api/resource.js';
const api = new resource('report');

export default {
    namespaced: true,
    state: {
        generalReportTitle: [],
        generalReportContent: [],
        generalReportTotal: [],
        generalReportTableSetting: {
            loading: true, searching: false, pageSetting: { total: 0, current: 1, pageSize: 999999 }
        },
        generalReportSelectData: {
        },

        therapistReportTitle: [],
        therapistReportContent: [],
        therapistReportTotal: [],
        therapistReportTableSetting: {
            loading: true, searching: false, pageSetting: { total: 0, current: 1, pageSize: 999999 }
        },
        therapistReportSelectData: {
        },
    },
    getters: {
    },
    mutations: {
        setList: (state, { name, data }) => {
            state[`${name}Title`]   = data.title || [];
            state[`${name}Content`] = data.content || [];
            state[`${name}Total`]   = data.total || [];
        },
        setLoading: (state, { name, data }) => {
            state[`${name}TableSetting`].loading     = data;
            state[`${name}TableSetting`].searching   = data;
        },
        setTableLoading: (state, { name, index, data }) => {
            state[`${name}List`][index].loading = data;
        },
        setPageSetting: (state, { name, type, data }) => {
            state[`${name}TableSetting`].pageSetting[type] = data;
        },
        setSelectAllData: (state, { name, data }) => {
            state[`${name}SelectData`] = data;
        },
        setSelectData: (state, { name, field, data }) => {
            state[`${name}SelectData`][field] = data;
        }
    },
    actions: {
        getGeneralReportList({ state, commit }) {
            return new Promise((resolve, reject) => {
                let selectFrom  = state.generalReportSelectData
                let pageSetting = state.generalReportTableSetting.pageSetting
                api.index({ selectFrom, pageSetting }).then(response => {
                    if (response.status) {
                        commit('setList', { name: 'generalReport', data: response.data });
                        commit('setLoading', { name: 'generalReport', data: false });
                    }
                    resolve(response);
                }).catch(error => {
                    reject(error);
                });
            });
        },
        getTherapistReportList({ state, commit }) {
            return new Promise((resolve, reject) => {
                let selectFrom  = state.therapistReportSelectData
                let pageSetting = state.therapistReportTableSetting.pageSetting
                api.index({ selectFrom, pageSetting }).then(response => {
                    if (response.status) {
                        commit('setList', { name: 'therapistReport', data: response.data });
                        commit('setLoading', { name: 'therapistReport', data: false });
                    }
                    resolve(response);
                }).catch(error => {
                    reject(error);
                });
            });
        },
    }
}
