import layout from '@/layout/index.vue';

export default {
  path: '/account',
  component: layout,
  meta: {
    title: '帳號',
    category: 'setting',
    icon: 'cilSettings',
    allow: 7,
  },
  children: [
    {
      path: '/account',
      name: 'account',
      meta: {
        title: '帳號管理',
        icon: 'cilSettings',
        allow: 7,
      },
      component: () => import('@/views/account/account.vue')
    }
  ]
}
