import request from '@/utils/request';

export function store(data) {
    return request({
        url: '/therapistBind',
        method: 'post',
        data
    });
}

export function destroy(id) {
    return request({
        url: `/therapistBind/${id}`,
        method: 'delete'
    });
}