<template>
    <!-- danger warning success primary info -->
    <CToaster placement="top-end" visible>
        <template v-for="item in store.state.toast.toastList">
            <CToast :color="item.color" class="text-white align-items-center" visible>
                <div class="d-flex">
                    <CToastBody>
                        {{ item.msg }}
                    </CToastBody>
                    <CToastClose class="me-2 m-auto" white />
                </div>
            </CToast>
        </template>
    </CToaster>
</template>

<script setup>
    import { useStore } from 'vuex';
    const store = useStore();
</script>