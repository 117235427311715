import '@/bootstrap';
import { isLogged, getToken, getExpires } from '@/utils/auth';
import store from '@/store/index.js'

// import useStore from "@/store/index.js";
// const store = useStore()
// const store   = useStore();

let isLock             = false;
let refreshSubscribers = [];

const service = window.axios.create({
    baseURL: '/api',
    timeout: 30000, // Request timeout
});

// Request intercepter
service.interceptors.request.use(
    config => {
        let token = getToken();
        if (isLogged()) {
            config.headers.Authorization = 'Bearer ' + token;

            // 需要刷新
            if (isTokenExpired() && ['/book/survey/content'].indexOf(config.url) < 0)
            {
                if (!isLock) {
                    isLock = true;
                    return new Promise((resolve, reject) => refreshToken(config, token, resolve, reject));
                }
                else
                {
                    if (['/auth/refresh', '/auth/logout', '/auth/refresha', '/book/survey/content'].indexOf(config.url) < 0)
                    {
                        let retry = new Promise((resolve, reject) => {
                            subscribeTokenRefresh((token) => {
                                config.headers.Authorization   = 'Bearer ' + token;
                                config.headers.ClinicId         = store.state.auth.clinic_id;
                                resolve(config);
                            })
                        })
                        return retry;
                    }
                    else
                    {
                        return config;
                    }
                }
            }
        }

        config.headers['ClinicId'] = store.state.auth.clinic_id;
        return config;
    },
    error => {
        Promise.reject(error);
    }
);

// response pre-processing
service.interceptors.response.use(
    response => {
        if (Object.keys(response.data).indexOf('msg') >= 0)
            if (response.data.status == 'error')
                errorMessage(response.data.msg);
            else
                successMessage(response.data.msg);

        return {
            status: response.data.status == 'ok',
            data: response.data.data
        };
    },
    error => {
        if (!error.response)
            return Promise.reject(error);

        switch (error.response.status) {
            case 401:
                errorMessage('登入失效，請重新登入!');
                store.dispatch('auth/logout').then(() => location.reload());
                break;
            case 403:
                errorMessage('無此功能操作權限!');
                break;
            case 413:
                errorMessage('超過可上傳檔案容量限制');
                break;
            case 422:
                // errorMessage(Object.values(error.response.data.message).join("<br/>"));
                errorMessage(error.response.data.message);
                break;
            default:
                errorMessage('請求失敗，請稍後重新嘗試');
                break;
        }
        return Promise.reject(error);
    }
);

function subscribeTokenRefresh(cb) {
    refreshSubscribers.push(cb);
}

function onRrefreshed(token) {
    refreshSubscribers.map(cb => cb(token));
}

function isTokenExpired() {
    let timestamp   = Math.round(new Date().getTime());
    let expires     = Number(getExpires());
    return timestamp - expires > 30 * 60 * 1000 && timestamp - expires < 119 * 60 * 1000;
}

function refreshToken(config, token, resolve, reject) {
    store.dispatch('auth/refresh').then((res) => {
        isLock = false;
        if (!res) {
            store.dispatch('auth/logout').then(() => location.reload());
            return;
        } else {
            config.headers.Authorization = 'Bearer ' + getToken();
            config.headers.ClinicId      = store.state.auth.clinic_id;
            resolve(config);
            onRrefreshed(getToken());
            refreshSubscribers = [];
        }
    }).catch(error => {
        reject(error);
    })
}

function errorMessage(msg) {
    store.commit('toast/setErrorMsg', msg);
}

function successMessage(msg) {
    store.commit('toast/setSuccessMsg', msg);
}

export default service;
