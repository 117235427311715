// import './styles/index.scss';
import './bootstrap';
import './permission';
import '@coreui/coreui/dist/css/coreui.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@/styles/iconfont/style.css';

import { createApp } from 'vue';

import app from '@/views/app.vue';
import router from './router';
import store from './store';
import directive from './directive';

import CoreuiVue from '@coreui/vue';
import CIcon from '@coreui/icons-vue';
import { iconsSet as icons } from '@/assets/icons';

import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

// 加載 Google API
const loadGoogleApi = () => {
    return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.src = 'https://apis.google.com/js/platform.js'; // 改用 platform.js
        script.async = true;
        script.defer = true;
        
        script.onload = () => {
            // 初始化 gapi
            gapi.load('client:auth2', () => {
                console.log('Google API 載入成功');
                resolve(window.gapi);
            });
        };
        
        script.onerror = () => {
            console.error('Failed to load Google API script');
            reject(new Error('Failed to load Google API'));
        };
        
        document.head.appendChild(script);
    });
};

const App = createApp(app);

// 初始化應用
const initApp = async () => {
    try {
        await loadGoogleApi();
        // console.log('Google API loaded and initialized');
        
        App.config.globalProperties.$gapiLoaded = true;
    } catch (error) {
        console.error('Failed to initialize Google API:', error);
        App.config.globalProperties.$gapiLoaded = false;
    } finally {
        App.use(router);
        App.use(store);
        App.use(CoreuiVue);
        App.provide('icons', icons);
        App.component('CIcon', CIcon);
        App.component('VueDatePicker', VueDatePicker);
        
        directive(App);
        
        App.mount('#app');
    }
};

initApp();
