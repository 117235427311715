import layout from '@/layout/index.vue';

export default {
	path: '/receipt',
	component: layout,
	meta: {
		title: '收據',
		category: 'account',
		icon: 'cilDescription',
		allow: 3,
	},
	children: [
		{
			path: '/receipt',
			name: 'receipt',
			meta: {
				title: '收據匯出',
				icon: 'cilDescription',
				allow: 3,
			},
			component: () => import('@/views/receipt/receipt.vue')
		}
	]
}