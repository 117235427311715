export default {
    namespaced: true,
    state: {
        bulletin: { need: false, list: [] },
        clinic: { need: false, list: [] },
        course: { need: false, list: [] },
        caseOption: { need: false, list: [] },
        duration: { need: false, list: [] },
        diagnosis: { need: false, list: [] },
        expense: { need: false, list: [] },
        room: { need: false, list: [] },
        systemBulletin: { need: false, list: [] },
        therapist: { need: false, list: [] },
        receiptSample: { need: false, list: [] },
    },
    getters: {
    },
    mutations: {
        setNeed: (state, { name, data }) => {
            // state[name]['need'] = data;
        },
        setList: (state, { name, data }) => {
            state[name]['list'] = data.list || [];
        },
    },
    actions: {
    }
}
