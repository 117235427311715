import { createStore } from 'vuex'

import auth from './modules/auth';
import admin from './modules/admin';
import book from './modules/book';
import bulletin from './modules/bulletin';
import business from './modules/business';
import cases from './modules/cases';
import clinic from './modules/clinic';
import course from './modules/course';
import caseOption from './modules/caseOption';
import caseSetting from './modules/caseSetting';
import expense from './modules/expense';
import duration from './modules/duration';
import diagnosis from './modules/diagnosis';
import mailSetting from './modules/mailSetting';
import order from './modules/order';
import orderFile from './modules/orderFile';
import orderDetail from './modules/orderDetail';
import orderComment from './modules/orderComment';
import orderExecute from './modules/orderExecute';
import questionnaire from './modules/questionnaire';
import room from './modules/room';
import router from './modules/router';
import report from './modules/report';
import receipt from './modules/receipt';
import receiptSample from './modules/receiptSample';
import receiptSampleBind from './modules/receiptSampleBind';
import select from './modules/select';
import survey from './modules/survey';
import systemBulletin from './modules/systemBulletin';
import toast from './modules/toast';
import therapist from './modules/therapist';
import therapistBind from './modules/therapistBind';
import therapistWork from './modules/therapistWork';
import therapistCourse from './modules/therapistCourse';
import therapistHoliday from './modules/therapistHoliday';
import google from './modules/google';

const store = createStore({
    state: {
        sidebarVisible: true,
        sidebarUnfoldable: false,
    },
    getters: {},
    mutations: {
        toggleSidebar(state) {
            state.sidebarVisible = !state.sidebarVisible
        },
        toggleUnfoldable(state) {
            state.sidebarUnfoldable = !state.sidebarUnfoldable
        },
        updateSidebarVisible(state, payload) {
            state.sidebarVisible = payload.value
        },
    },
    actions: {},
    modules: {
        auth,
        admin,
        book,
        bulletin,
        business,
        cases,
        clinic,
        course,
        caseOption,
        caseSetting,
        expense,
        duration,
        diagnosis,
        mailSetting,
        order,
        orderFile,
        orderDetail,
        orderComment,
        orderExecute,
        questionnaire,
        room,
        router,
        report,
        receipt,
        receiptSample,
        receiptSampleBind,
        select,
        survey,
        systemBulletin,
        toast,
        therapist,
        therapistBind,
        therapistWork,
        therapistCourse,
        therapistHoliday,
        google
    }
});

export default store;
