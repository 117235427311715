import { content, store } from '@/api/survey';

export default {
    namespaced: true,
    state: {
        content: {}
    },
    getters: {
    },
    mutations: {
        setContent: (state, { name, data }) => {
            state.content = data || {};
        }
    },
    actions: {
        getContent({ state, commit }, params) {
            return new Promise((resolve, reject) => {
                content(params).then(response => {
                    if (response.status)
                        commit('setContent', { name: 'survey', data: response.data });
                    resolve(response);
                }).catch(error => {
                    reject(error);
                });
            });
        },
        insert({}, data) {
            return new Promise((resolve, reject) => {
                store(data).then(response => {
                    resolve(response.status);
                }).catch(error => {
                    reject(error);
                });
            });
        }
    }
}
