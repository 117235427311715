import resource from '@/api/resource.js';
const api = new resource('receiptSampleBind');

export default {
    namespaced: true,
    state: {
    },
    getters: {
    },
    mutations: {
    },
    actions: {
        insert({}, data) {
            return new Promise((resolve, reject) => {
                api.store(data).then(response => {
                    resolve(response.status);
                }).catch(error => {
                    reject(error);
                });
            });
        },
        delete({ commit }, id) {
            return new Promise((resolve, reject) => {
                api.destroy(id).then(response => {
                    resolve(response.status);
                }).catch(error => {
                    reject(error);
                });
            });
        }
    }
}
