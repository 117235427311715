import resource from '@/api/resource.js';
import {createGoogleCalendarEvent} from '@/utils/googleApi';
const api = new resource('order');

export default {
    namespaced: true,
    state: {
        info: {},
        list: [],
        tableSetting: {
            loading: true,
            searching: false,
            pageSetting: {
                total: 0,
                current: 1,
                pageSize: 10
            }
        },
        selectData: {
        },
        formData: {
        },
        cycleFormData: {
        },
    },
    getters: {
    },
    mutations: {
        setList: (state, { name, data }) => {
            if (Object.keys(data).indexOf('list') >= 0 && data.list.length > 0) {
                data.list.forEach(function(item, index) {
                    data.list[index].loading = false;
                });
            }

            state['list'] = data.list || [];
            state['tableSetting'].pageSetting.total = data.total || 0;
        },
        setInfo: (state, { name, data }) => {
            state['info'] = data || {};
        },
        setLoading: (state, { name, data }) => {
            state['tableSetting'].loading     = data;
            state['tableSetting'].searching   = data;
        },
        setTableLoading: (state, { name, index, data }) => {
            state['list'][index].loading = data;
        },
        setPageSetting: (state, { name, type, data }) => {
            state['tableSetting'].pageSetting[type] = data;
        },
        setSelectData: (state, { name, field, data }) => {
            state['selectData'][field] = data;
        },
        setSelectAllData: (state, { name, data }) => {
            state['selectData'] = data;
        },
        setFormData: (state, { name, data }) => {
            state['formData'] = data;
        },
        setCycleFormData: (state, { name, data }) => {
            state['cycleFormData'] = data;
        },
        setFormDataPush: (state, { name, field, data }) => {
            state['formData'][field].push(data);
        }
    },
    actions: {
        // async insertOrderWithGoogleCalendarEvent({ commit }, orderData) {
        //     try {
        //         // 創建 Google Calendar 事件並獲取 event_id
        //         const googleEvent = await createGoogleCalendarEvent({
        //             title: orderData.title,
        //             description: orderData.description,
        //             start: orderData.start,
        //             end: orderData.end,
        //         });

        //         // 將 Google Calendar 的 event_id 儲存在 orderData 中
        //         orderData.google_calendar_event_id = googleEvent.id;

        //         // 呼叫後端 API，將 orderData 存入資料庫
        //         const response = await api.store(orderData);
        //         if (response.status) {
        //             console.log('Order and Google event successfully created');
        //         } else {
        //             console.error('Failed to save order in database');
        //         }
        //         return response.status;
        //     } catch (error) {
        //         console.error('Failed to create Google Calendar event or save order:', error);
        //         throw error;
        //     }
        // },
        getList({ state, commit }) {
            return new Promise((resolve, reject) => {
                let selectFrom  = state.selectData
                let pageSetting = state.tableSetting.pageSetting
                api.index({ selectFrom, pageSetting }).then(response => {
                    if (response.status) {
                        commit('setList', { name: 'order', data: response.data });
                        commit('setLoading', { name: 'order', data: false });


                        // Log to check if google_calendar_event_id is present
                        // console.log('List Response:', response.data);
                        // response.data.list.forEach(item => {
                        //     console.log('google_calendar_event_id in list item:', item.google_calendar_event_id);
                        // });

                    }
                    resolve(response);
                }).catch(error => {
                    reject(error);
                });
            });
        },
        getInfo({ state, commit }, id) {
            return new Promise((resolve, reject) => {
                api.show(id).then(response => {
                    if (response.status) {
                        commit('setInfo', { name: 'order', data: response.data });

                        // // Log to check if google_calendar_event_id is present in the info response
                        // console.log('Info Response:', response.data);
                        // console.log('google_calendar_event_id in info:', response.data.google_calendar_event_id);
                    }
                    resolve(response);
                }).catch(error => {
                    reject(error);
                });
            });
        },
        insert({}, data) {
            return new Promise((resolve, reject) => {
                api.store(data).then(response => {
                    resolve(response.status);
                }).catch(error => {
                    reject(error);
                });
            });
        },
        insertCycle({}, data) {
            return new Promise((resolve, reject) => {
                api.insertCycle(data).then(response => {
                    resolve(response);
                }).catch(error => {
                    reject(error);
                });
            });
        },
        update({ commit }, { id, data }) {
            return new Promise((resolve, reject) => {
                api.update({ id, data }).then(response => {
                    resolve(response.status);
                }).catch(error => {
                    reject(error);
                });
            });
        },
        status({ commit }, { id, data }) {
            return new Promise((resolve, reject) => {
                api.status({ id, data }).then(response => {
                    resolve(response.status);
                }).catch(error => {
                    reject(error);
                });
            });
        },
        delete({ commit }, id) {
            return new Promise((resolve, reject) => {
                api.destroy(id).then(response => {
                    resolve(response.status);
                }).catch(error => {
                    reject(error);
                });
            });
        },
        select({ rootState, commit}) {
            return new Promise((resolve, reject) => {
                if (rootState.select.order.need)
                    resolve(true);

                api.select().then(response => {
                    if (response.status) {
                        commit('select/setList', { name: 'order', data: response.data }, { root: true });
                    }
                    resolve(response.status);
                }).catch(error => {
                    reject(error);
                });
            });
        }
    }
}

