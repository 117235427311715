<template>
    <CModal scrollable alignment="center" class="defaultModal" :visible="open" @close="() => { open = false }">
        <CModalHeader>
            <CModalTitle>治療所切換</CModalTitle>
        </CModalHeader>
        <CModalBody>
            <CInputGroup>
                <CFormSelect v-model="clinicId" aria-label="Example select with button addon">
                    <option v-if="userType == 1" value="all">全部</option>
                    <option v-for="item in clinicSelect" :value="item.id">
                        {{ item.name }}
                    </option>
                </CFormSelect>
                <CButton type="button" color="gray" @click="confirmData">切換</CButton>
            </CInputGroup>
        </CModalBody>
    </CModal>
</template>

<script setup>
    import { useStore } from 'vuex';
    import { ref, defineProps, defineEmits, watch, onMounted, computed } from 'vue';

    const store = useStore();
    const emit  = defineEmits(['closeModal']);
    const props = defineProps({
        modelValue: { type: Object, default: { msg: null, open: 0, data: null } }
    });

    const open      = ref(false);
    const clinicId  = ref(store.state.auth.clinic_id);

    const userType = computed(() => {
        return store.state.auth.user.type || null;
    });

    const userClinicId = computed(() => {
        return store.state.auth.user.clinic_id || null;
    });

    const clinicSelect = computed(() => {
        return store.state.select.clinic.list.filter((item) => {
            return userClinicId.value.indexOf('*') >= 0 || userClinicId.value.indexOf(item.id) >= 0;
        });
    });

    const getSelectClinic = () => {
        store.dispatch('clinic/select');
    };

    const closeModal = () => {
        emit('closeModal', 'clinicModal');
    };

    const confirmData = () => {
        if (Number(clinicId.value) != 'NAN' && clinicId.value != store.state.auth.clinic_id) {
            store.dispatch('auth/changeClinicId', clinicId.value).then(() => {
                location.reload()
            });
        }
    };

    watch(() => store.state.auth.clinic_id, (newClinicId) => {
        console.log('watch ::: ' + newClinicId)
    });

    watch(() => props.modelValue.open, (newOpen) => {
        open.value = newOpen > 0;
    });

    onMounted(() => {
        getSelectClinic();
    });
</script>

