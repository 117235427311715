import layout from '@/layout/index.vue';

export default {
  path: '/bulletin',
  component: layout,
  meta: {
    title: '公告',
    // category: 'resource',
    category: 'stOwner',
    icon: 'cilBullhorn',
    allow: 3,
  },
  children: [
    {
      path: '/bulletin',
      name: 'bulletin',
      meta: {
        title: '公告管理',
        icon: 'cilBullhorn',
        allow: 3,
      },
      component: () => import('@/views/bulletin/bulletin.vue')
    },
    {
      path: '/bulletinForm/:id',
      name: 'bulletinForm',
      meta: {
        title: '編輯公告',
        icon: 'cilBullhorn',
        allow: 3,
        hide: true
      },
      component: () => import('@/views/bulletin/bulletinForm.vue')
    }
  ]
}
