import { content, field, questionnaire, store } from '@/api/book';

export default {
    namespaced: true,
    state: {
        content: {},
        field: [],
        questionnaire: [],
    },
    getters: {
    },
    mutations: {
        setContent: (state, { name, data }) => {
            state.content = data || {};
        },
        setField: (state, { name, data }) => {
            state.field = data || [];
        },
        setQuestionnaire: (state, { name, data }) => {
            state.questionnaire = data || [];
        },
    },
    actions: {
        getContent({ state, commit }, params) {
            return new Promise((resolve, reject) => {
                content(params).then(response => {
                    if (response.status)
                        commit('setContent', { name: 'book', data: response.data });
                    resolve(response);
                }).catch(error => {
                    reject(error);
                });
            });
        },
        getField({ state, commit }, params) {
            return new Promise((resolve, reject) => {
                field(params).then(response => {
                    if (response.status)
                        commit('setField', { name: 'book', data: response.data });
                    resolve(response);
                }).catch(error => {
                    reject(error);
                });
            });
        },
        getQuestionnaire({ state, commit }, params) {
            return new Promise((resolve, reject) => {
                questionnaire(params).then(response => {
                    if (response.status)
                        commit('setQuestionnaire', { name: 'book', data: response.data });
                    resolve(response);
                }).catch(error => {
                    reject(error);
                });
            });
        },
        insert({}, { id, data }) {
            return new Promise((resolve, reject) => {
                store({ id, data }).then(response => {
                    resolve(response.status);
                }).catch(error => {
                    reject(error);
                });
            });
        }
    }
}
