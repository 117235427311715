import layout from '@/layout/index.vue';

export default {
    path: '/room',
    component: layout,
    meta: {
        title: '教室',
        category: 'resource',
        icon: 'cilRoom',
        allow: 3,
    },
    children: [
        {
            path: '/room',
            name: 'room',
            meta: {
                title: '教室',
                icon: 'cilRoom',
                allow: 3,
            },
            component: () => import('@/views/room/room.vue')
        },
        {
            path: '/roomForm/:id',
            name: 'roomForm',
            meta: {
                title: '編輯教室',
                icon: 'cilRoom',
                allow: 3,
                hide: true
            },
            component: () => import('@/views/room/roomForm.vue')
        }
    ]
}
