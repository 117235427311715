import layout from '@/layout/index.vue';

export default {
  path: '/account',
  component: layout,
  meta: {
    title: '待排',
    // category: 'setting',
    category: 'stOwner',
    icon: 'cilList',
    allow: 3,
  },
  children: [
    {
      path: '/bookInfo',
      name: 'bookInfo',
      meta: {
        title: '待排管理',
        icon: 'cilList',
        allow: 3,
      },
      component: () => import('@/views/settingBook/bookInfoForm.vue')
    },
    {
      path: '/bookOption',
      name: 'bookOption',
      meta: {
        title: '待排項目管理',
        icon: 'cilList',
        allow: 3,
        hide: true

      },
      component: () => import('@/views/settingBook/bookOption.vue')
    },
    {
      path: '/bookOptionForm/:id',
      name: 'bookOptionForm',
      meta: {
        title: '待排資料管理',
        icon: 'cilList',
        allow: 3,
        hide: true
      },
      component: () => import('@/views/settingBook/bookOptionForm.vue')
    }
  ]
}
