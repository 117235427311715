//共用目錄
export default [
  {
    path: '/login',
    name: 'login',
    meta: { hideMenu: true },
    component: () => import('@/views/login/index.vue')
  },
  {
    path: '/booking',
    name: 'booking',
    meta: { hideMenu: true },
    component: () => import('@/views/booking/index.vue')
  },
  {
    path: '/book/:id',
    name: 'book',
    meta: { hideMenu: true },
    component: () => import('@/views/book/index.vue')
  },
  {
    path: '/survey/:code',
    name: 'survey',
    meta: { hideMenu: true },
    component: () => import('@/views/survey/index.vue')
  },

  {
    path: '/401',
    name: 'error401',
    meta: { hideMenu: true },
    component: () => import('@/views/errorPage/401.vue')
  },
  {
    path: '/404',
    name: 'error404',
    meta: { hideMenu: true },
    component: () => import('@/views/errorPage/404.vue')
  },
  {
    path: '/500',
    name: 'error500',
    meta: { hideMenu: true },
    component: () => import('@/views/errorPage/500.vue')
  },
  {
    path: '/newCase',
    name: 'newCase',
    meta: { hideMenu: true },
    component: () => import('@/views/newCase/index.vue')
  },
]

