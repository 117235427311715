import layout from '@/layout/index.vue';

export default {
  path: '/questionnaire',
  component: layout,
  meta: {
    category: 'dyAdmin',
    allow: 1,
  },
  children: [
    {
      path: '/questionnaire',
      name: 'questionnaire',
      meta: {
        title: '調查表模板',
        icon: 'cilClipboard',
        allow: 3,
      },
      component: () => import('@/views/questionnaire/questionnaire.vue')
    },
    {
      path: '/questionnaireForm/:id',
      name: 'questionnaireForm',
      meta: {
        title: '編輯調查表',
        icon: 'cilClipboard',
        allow: 1,
        hide: true
      },
      component: () => import('@/views/questionnaire/questionnaireForm.vue')
    }
  ]
}
