import layout from '@/layout/index.vue';

export default {
	path: '/order',
	component: layout,
	meta: {
		title: '預約',
		category: 'course',
		icon: 'cilClipboard',
		allow: 7,
	},
	children: [
		{
			path: '/order',
			name: 'order',
			meta: {
				title: '課程查詢',
				icon: 'cilClipboard',
				allow: 7
			},
			component: () => import('@/views/order/order.vue')
		},
		// {
		// 	path: '/orderForm',
		// 	name: 'orderForm',
		// 	meta: {
		// 		title: '預約',
		// 		icon: 'cilClipboard',
		// 		allow: 7
		// 	},
		// 	component: () => import('@/views/order/orderForm.vue')
		// },
		// {
		// 	path: '/cycleForm',
		// 	name: 'cycleForm',
		// 	meta: {
		// 		title: '週期預約',
		// 		icon: 'cilClipboard',
		// 		allow: 7
		// 	},
		// 	component: () => import('@/views/order/cycleForm.vue')
		// },
		// {
		// 	path: '/Appointment',
		// 	name: 'listAppointment',
		// 	meta: {
		// 		title: '日曆預約',
		// 		icon: 'cilClipboard',
		// 		allow: 7
		// 	},
		// 	component: () => import('@/views/appointment/listAppointment.vue')
		// },
		
		{
			path: '/showAppointment',
			name: 'showAppointment',
			meta: {
				title: '預約管理',
				icon: 'cilClipboard',
				allow: 7
			},
			component: () => import('@/views/appointment/showAppointment.vue')
		},
		
		
		// {
		// 	path: '/orderForm2',
		// 	name: 'orderForm2',
		// 	meta: {
		// 		title: '預約課程2',
		// 		icon: 'cilClipboard',
		// 		allow: 1,
		// 		hide: true
		// 	},
		// 	component: () => import('@/views/order/orderForm2.vue')
		// }
		// ,
		// {
		// 	path: '/orderForm3',
		// 	name: 'orderForm3',
		// 	meta: {
		// 		title: '預約課程3',
		// 		icon: 'cilClipboard',
		// 		allow: 1,
		// 		hide: true
		// 	},
		// 	component: () => import('@/views/order/orderForm3.vue')
		// }
	]
}