import layout from '@/layout/index.vue';

export default {
  path: '/commission',
  component: layout,
  meta: {
    title: '傭金',
    // category: 'account',
    category: 'stInfo',
    icon: 'cilWallet',
    allow: 7,
  },
  children: [
    {
      path: '/commission',
      name: 'commission',
      meta: {
        title: '傭金比例',
        icon: 'cilWallet',
        allow: 7,
      },
      component: () => import('@/views/commission/commission.vue')
    },
    {
      path: '/commissionForm/:id',
      name: 'commissionForm',
      meta: {
        title: '編輯課程',
        icon: 'cilWallet',
        allow: 7,
        hide: true
      },
      component: () => import('@/views/commission/commissionForm.vue')
    }
  ]
}
