import { index, store, show, update, destroy } from '@/api/therapistWork';

export default {
    namespaced: true,
    state: {
        info: {},
        list: [],
        tableSetting: {
            loading: true,
            searching: false,
            pageSetting: {
                total: 0,
                current: 1,
                pageSize: 10000
            }
        },
        selectData: {
            therapist_id: []
        },
    },
    getters: {
    },
    mutations: {
        setList: (state, { name, data }) => {
            if (Object.keys(data).indexOf('list') >= 0 && data.list.length > 0) {
                data.list.forEach(function(item, index) {
                    data.list[index].loading = false;
                });
            }

            state['list'] = data.list || [];
            state['tableSetting'].pageSetting.total = data.total || 0;
        },
        setInfo: (state, { name, data }) => {
            state['info'] = data || {};
        },
        setLoading: (state, { name, data }) => {
            state['tableSetting'].loading     = data;
            state['tableSetting'].searching   = data;
        },
        setTableLoading: (state, { name, index, data }) => {
            state['list'][index].loading = data;
        },
        setPageSetting: (state, { name, type, data }) => {
            state['tableSetting'].pageSetting[type] = data;
        },
        setSelectData: (state, { name, field, data }) => {
            state['selectData'][field] = data;
        }
    },
    actions: {
        // getList({ state, commit }) {
        //     return new Promise((resolve, reject) => {
        //         let selectFrom  = state.selectData
        //         // if (!Array.isArray(selectFrom.therapist_id)) {
        //         //     selectFrom.therapist_id = []; // 確保 therapist_id 始終是陣列
        //         // }
        //         console.log('therpistWork.js - selectFrom:', selectFrom);
        //         let pageSetting = state.tableSetting.pageSetting
        //         // console.log('selectFrom:', selectFrom);  // 查看 selectFrom 的值
        //         index({ selectFrom, pageSetting }).then(response => {
        //             if (response.status) {
        //                 commit('setList', { name: 'therapistWork', data: response.data });
        //                 commit('setLoading', { name: 'therapistWork', data: false });
        //             } else {
        //                 console.error('Invalid response data structure:', response.data);
        //             }
        //             resolve(response);
        //         }).catch(error => {
        //             reject(error);
        //         });
                                                
        //     });
        // },
        getList({ state, commit }) {
            return new Promise((resolve, reject) => {
                let selectFrom  = state.selectData
                let pageSetting = state.tableSetting.pageSetting

                // console.log('selectFrom:', selectFrom);
                // console.log('pageSetting:', pageSetting);

                index({ selectFrom, pageSetting }).then(response => {
                    if (response.status) {
                        commit('setList', { name: 'therapistWork', data: response.data });
                        commit('setLoading', { name: 'therapistWork', data: false });
                    }
                    resolve(response);
                }).catch(error => {
                    reject(error);
                });
            });
        },
        // getInfo({ state, commit }, id) {
        //     return new Promise((resolve, reject) => {
        //         show(id).then(response => {
        //             if (response.status) {
        //                 commit('setInfo', { name: 'therapistWork', data: response.data });
        //             }
        //             resolve(response);
        //         }).catch(error => {
        //             reject(error);
        //         });
        //     });
        // },
        // getIfo 沒有功能 也沒有使用到
        getInfo({ state, commit }, id) {
            return new Promise((resolve, reject) => {
                // show(id).then(response => {
                //     if (response.status) {
                //         commit('setInfo', { name: 'therapistWork', data: response.data });
                //     } else {
                //         console.error('Invalid response data structure:', response.data);
                //     }
                //     resolve(response);
                // }).catch(error => {
                //     console.error('獲取治療師工作時間表失敗:', error);
                //     reject(error);
                // });

                show(id).then(response => {
                    // 處理成功回應
                }).catch(error => {
                    console.error('獲取治療師工作時間表失敗:', error);
                    if (error.response) {
                        console.error('Response data:', error.response.data);
                        console.error('Status:', error.response.status);
                        console.error('Headers:', error.response.headers);
                    } else if (error.request) {
                        console.error('No response received:', error.request);
                    } else {
                        console.error('Error:', error.message);
                    }
                    reject(error);
                });
            });
        },
        
        insert({}, data) {
            return new Promise((resolve, reject) => {
                store(data).then(response => {
                    resolve(response.status);
                }).catch(error => {
                    reject(error);
                });
            });
        },
        update({ commit }, { id, data }) {
            return new Promise((resolve, reject) => {
                update({ id, data }).then(response => {
                    resolve(response.status);
                }).catch(error => {
                    reject(error);
                });
            });
        },
        delete({ commit }, id) {
            return new Promise((resolve, reject) => {
                destroy(id).then(response => {
                    resolve(response.status);
                }).catch(error => {
                    reject(error);
                });
            });
        }
    }
}
