import { createRouter, createWebHashHistory } from 'vue-router';

import pubRouter from './pubRouter';
import homeRouter from './homeRouter';

import orderRouter from './orderRouter';

import reportRouter from './reportRouter';
import receiptRouter from './receiptRouter';
import commissionRouter from './commissionRouter';

import caseRouter from './caseRouter';
import therapistRouter from './therapistRouter';
import roomRouter from './roomRouter';
import courseRouter from './courseRouter';
import scheduleRouter from './scheduleRouter';
import bulletinRouter from './bulletinRouter';

import adminRouter from './adminRouter';
import clinicRouter from './clinicRouter';
import optionRouter from './optionRouter';
import mailSettingRouter from './mailSettingRouter';
import receiptSampleRouter from './receiptSampleRouter';

import settingBulletinRouter from './settingBulletinRouter';
import accountRouter from './accountRouter';
import settingBookRouter from './settingBookRouter';
import questionnaireRouter from './questionnaireRouter';

// 添加 Google 回調路由
// const googleCallbackRoute = {
//   path: '/oauth2/google/callback',
//   name: 'googleCallback',
//   component: () => import('@/views/GoogleCallback.vue'),
// };

export const asyncRoutes = [
  homeRouter,
  orderRouter,
  // 課程管理
  caseRouter,
  courseRouter,

  // 帳務管理
  reportRouter,
  receiptRouter,

  // 治療師管理
  therapistRouter,
  commissionRouter,

  // 所長管理
  clinicRouter,
  roomRouter,
  scheduleRouter,
  optionRouter,

  // 管理員管理
  adminRouter,
  settingBulletinRouter,

  // Other settings
  mailSettingRouter,
  receiptSampleRouter,
  bulletinRouter,
  accountRouter,
  settingBookRouter,
  questionnaireRouter,

  // googleCallbackRoute,

  { path: '/:pathMatch(.*)*', name: 'NotFound', redirect: '/404', hidden: true }
]

const router = createRouter({
  routes: [...pubRouter],
  history: createWebHashHistory('/'),
  scrollBehavior: () => ({ top: 0 }),
})

export function resetRouter() {
  const asyncRouterNameArr = asyncRoutes.map((mItem) => mItem.name)
  asyncRouterNameArr.forEach((name) => {
    if (router.hasRoute(name))
      router.removeRoute(name);
  })
}

export default router;
