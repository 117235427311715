import Cookies from 'js-cookie';

const tokenKey 		= 'authToken';
const expiresKey 	= 'expiresTime';

export function isLogged() {
	return !!Cookies.get(tokenKey);
}

export function setToken(token) {
	return Cookies.set(tokenKey, token);
}

export function getToken() {
	return Cookies.get(tokenKey);
}

export function removeToken() {
	return Cookies.remove(tokenKey);
}

export function setExpires() {
	return Cookies.set(expiresKey, Math.round(new Date().getTime()));
}

export function getExpires() {
	return Cookies.get(expiresKey)
}