import resource from '@/api/resource.js';
const api = new resource('orderExecute');

export default {
    namespaced: true,
    state: {
        info: {},
        list: [],
        tableSetting: {
            loading: true,
            searching: false,
            pageSetting: {
                total: 0,
                current: 1,
                pageSize: 99999  // 無限
            }
        },
        selectData: {
        },
        formData: {
        },
        showListId: null,
        showInfoId: null
    },
    getters: {
    },
    mutations: {
        setList: (state, { name, data }) => {
            // console.log('Setting list with data:', data);
            if (Object.keys(data).indexOf('list') >= 0 && data.list.length > 0) {
                data.list.forEach(function(item, index) {
                    data.list[index].loading    = false;
                    data.list[index].showStatus = false;
                    data.list[index].showList   = state.showListId == item.id;
                    data.list[index].showInfo   = state.showInfoId == item.order_detail[0].cases_id;
                    item.order_detail.forEach(function(value, key) {
                        data.list[index].order_detail[key].showStatus = false;
                        data.list[index].order_detail[key].showInfo   = state.showInfoId == value.cases_id;
                        data.list[index].order_detail[key].formData   = {
                            order_id: item.order_id,
                            order_detail_id: value.id,
                            cases_id: value.cases_id,
                            google_calendar_event_id: item.google_calendar_event_id,
                            remark: null,
                            file_name: null,
                            file: []
                        };
                    });
                });
            }

            state['list'] = data.list || [];
            state['tableSetting'].pageSetting.total = data.total || 0;

            state['showListId'] = null;
            state['showInfoId'] = null;
            // console.log('Updated state list:', state['list']);
        },
        setInfo: (state, { name, data }) => {
            state['info'] = data || {};
        },
        setLoading: (state, { name, data }) => {
            state['tableSetting'].loading     = data;
            state['tableSetting'].searching   = data;
        },
        setTableLoading: (state, { name, index, data }) => {
            state['list'][index].loading = data;
        },
        setPageSetting: (state, { name, type, data }) => {
            state['tableSetting'].pageSetting[type] = data;
        },
        setSelectData: (state, { name, field, data }) => {
            state['selectData'][field] = data;
        },
        setSelectAllData: (state, { name, data }) => {
            state['selectData'] = data;
        },
        setFormData: (state, { name, data }) => {
            state['formData'] = data;
        },
        setFormDataPush: (state, { name, field, data }) => {
            state['formData'][field].push(data);
        },
        setShowListId: (state, { name, data }) => {
            state['showListId'] = data;
        },
        setShowInfoId: (state, { name, data }) => {
            state['showInfoId'] = data;
        }
    },
    actions: {
        getList({ state, commit }) {
            return new Promise((resolve, reject) => {
                let selectFrom  = state.selectData;
                let pageSetting = state.tableSetting.pageSetting;
                // console.log('讀取資料的過濾條件 getList called with selectFrom:', selectFrom); //讀取資料的過濾條件
                // console.log('orderExecute getList called with selectFrom:', selectFrom);
                api.index({ selectFrom, pageSetting }).then(response => {
                    if (response.status) {
                        // console.log('API response data:', response.data);
                        commit('setList', { name: 'orderExecute', data: response.data });
                        commit('setLoading', { name: 'orderExecute', data: false });
                        // console.log('API 讀取的結果 Data fetched:', response.data);
                    }
                    resolve(response);
                }).catch(error => {
                    // console.error("Error fetching list:", error);
                    reject(error);
                });
            });
        },

        // getList({ state, commit }, payload = {}) {
        //     return new Promise((resolve, reject) => {
        //         let selectFrom = payload.selectData || state.selectData;
        //         let pageSetting = state.tableSetting.pageSetting;
        //         api.index({ selectFrom, pageSetting }).then(response => {
        //             if (response.status) {
        //                 commit('setList', { name: 'orderExecute', data: response.data });
        //                 commit('setLoading', { name: 'orderExecute', data: false });
        //             }
        //             resolve(response);
        //         }).catch(error => {
        //             console.error("Error fetching list:", error);
        //             reject(error);
        //         });
        //     });
        // },
        getInfo({ state, commit }, id) {
            return new Promise((resolve, reject) => {
                api.show(id).then(response => {
                    if (response.status) {
                        commit('setInfo', { name: 'orderExecute', data: response.data });
                    }
                    resolve(response);
                }).catch(error => {
                    reject(error);
                });
            });
        },
        insert({}, data) {
            return new Promise((resolve, reject) => {
                api.store(data).then(response => {
                    resolve(response.status);
                }).catch(error => {
                    reject(error);
                });
            });
        },
        update({ commit }, { id, data }) {
            return new Promise((resolve, reject) => {
                api.update({ id, data }).then(response => {
                    resolve(response.status);
                }).catch(error => {
                    reject(error);
                });
            });
        },
        reserve({}, data) {
            return new Promise((resolve, reject) => {
                api.reserve(data).then(response => {
                    resolve(response.status);
                }).catch(error => {
                    reject(error);
                });
            });
        },
        copy({}, data) {
            return new Promise((resolve, reject) => {
                api.copy(data).then(response => {
                    resolve(response.status);
                }).catch(error => {
                    reject(error);
                });
            });
        },
        status({ commit }, { id, data }) {
            return new Promise((resolve, reject) => {
                api.status({ id, data }).then(response => {
                    resolve(response.status);
                }).catch(error => {
                    reject(error);
                });
            });
        },
        delete({ commit }, id) {
            return new Promise((resolve, reject) => {
                api.destroy(id).then(response => {
                    resolve(response.status);
                }).catch(error => {
                    reject(error);
                });
            });
        },
        select({ rootState, commit}) {
            return new Promise((resolve, reject) => {
                if (rootState.select.orderExecute.need)
                    resolve(true);

                api.select().then(response => {
                    if (response.status) {
                        commit('select/setList', { name: 'orderExecute', data: response.data }, { root: true });
                    }
                    resolve(response.status);
                }).catch(error => {
                    reject(error);
                });
            });
        }
    }
}
