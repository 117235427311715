// 新建文件 store/modules/google.js
export default {
    namespaced: true,
    state: {
        isConnected: false
    },
    mutations: {
        SET_GOOGLE_CONNECTION(state, status) {
            state.isConnected = status;
        }
    },
    actions: {
        updateGoogleConnection({ commit }, status) {
            commit('SET_GOOGLE_CONNECTION', status);
        },
        async createCalendarEvent({ commit }, eventData) {
            try {
                const response = await createGoogleCalendarEvent(eventData);
                return response;
            } catch (error) {
                console.error('創建 Google Calendar 事件失敗:', error);
                throw error;
            }
        },
        async updateCalendarEvent({ commit }, { eventId, eventData }) {
            try {
                const response = await updateGoogleCalendarEvent(eventId, eventData);
                return response;
            } catch (error) {
                console.error('更新 Google Calendar 事件失敗:', error);
                throw error;
            }
        }
    }
};

