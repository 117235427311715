export default {
    namespaced: true,
    state: {
        toastList: [],
    },
    getters: {
    },
    mutations: {
        setToastList(state, list) {
            state.toastList.push(list);
        },
        setErrorMsg(state, msg) {
            state.toastList.push({ color: 'danger', msg });
        },
        setSuccessMsg(state, msg) {
            state.toastList.push({ color: 'success', msg });
        },
    },
    actions: {
    }
}
