import pubRouter from '@/router/pubRouter';
import { asyncRoutes } from '@/router';

export default {
    namespaced: true,
	state: {
		routers: pubRouter,
        addRouters: []
  	},
    getters: {
        // menuList: (state, getters, rootState, rootGetters) => () => {
        //     return getMenuByRouter(state.routers, []);
        // },
        menuList: (state, getters, rootState, rootGetters) => () => {
            let data = getMenuByRouter(state.routers, []);
            let list = [
                { name: '', category: '', children: [] },
                { name: '課程管理', category: 'course', children: [] },
                { name: '帳務管理', category: 'account', children: [] },
                // { name: '資源管理', category: 'resource', children: [] },
                // { name: '系統管理', category: 'system', children: [] },
                // { name: '系統設置', category: 'setting', children: [] }
                { name: '檢視資料', category: 'stInfo', children: [] },
                { name: '所長管理', category: 'stOwner', children: [] },
                { name: '管理員管理', category: 'dyAdmin', children: [] }
            ];

            return list.map((item) => {
                data.filter((it) => {
                    return it.category == item.category;
                }).forEach((it) => {
                    it.children.forEach((i) => {
                        if (!i.hide)
                            item.children.push(i);
                    });
                });                
                return item;
            }).filter((item) => {
                return item.children.length > 0;
            });
        },
    },
    mutations: {
        setRoutes: (state, routers) => {
            state.addRouters = routers;
            state.routers    = pubRouter.concat(routers);
        }
    },
  	actions: {
        async generateRoutes({ rootState, rootGetters, commit }, { type }) {
            commit('setRoutes', filterAsyncRouter(asyncRoutes, type));
        }
    }
}

function filterAsyncRouter(asyncRouterMap, type) {
    return asyncRouterMap.filter(route => {
        if (Object.keys(route).indexOf('meta') < 0 || Object.keys(route.meta).indexOf('allow') < 0)
            return false;

        if ((route.meta.allow & type) == 0)
            return false;

        if (Object.keys(route).indexOf('children') >= 0 && route.children.length > 0)
            route.children = filterAsyncRouter(route.children, type);

        return true;
    });
}

function hasItemKey(item, name) {
    if (Object.keys(item).indexOf(name) < 0)
        return false;

    return item[name];
}

function hasItemLength(item) {
    if (Object.keys(item).indexOf(name) < 0)
        return false;

    return item[name].length !== 0;
}

function getMenuByRouter(routers, access) {
    let data = []
    routers.forEach((item) => {
        if (!item.meta || (item.meta && item.meta.hideMenu))
            return;

        let obj = {
            name: item.name,
            path: item.path,
            icon: (item.meta && item.meta.icon) || '',
            title: (item.meta && item.meta.title) || '',
            category: (item.meta && item.meta.category) || '',
            hide: (item.meta && item.meta.hide) || false,
        }

        if (item.children && item.children.length > 0)
            obj.children = getMenuByRouter(item.children, access);

        if (item.meta && item.meta.href)
            obj.href = item.meta.href;

        // if (showThisMenuEle(item, access))
            data.push(obj)
    });

    return data;
}