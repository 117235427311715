<template>
    <CSidebar position="fixed" :unfoldable="sidebarUnfoldable" :visible="sidebarVisible" @visible-change="toggleSidebar($event)">
        <!-- @visible-change="(event) => console.log(event)" -->
        <CSidebarBrand>
            <div class="sidebarClinic sidebar-brand-full fw-700">{{ clinicName }}</div>
            <CIcon custom-class-name="sidebar-brand-narrow" :icon="cilHospital" :height="35" />
        </CSidebarBrand>
        <!-- <AppSidebarNav /> -->

        <CSidebarNav>
            <template v-for="item in menuList">
                <li v-show="item.category != ''" class="nav-title">{{ item.name }}</li>
                <template v-for="obj in item.children">
                    <CNavItem :href="`#${obj.path}`" :active="obj.path == route.path">
                        <CIcon customClassName="nav-icon" :icon="obj.icon" />
                        {{ obj.title }}
                    </CNavItem>
                </template>
            </template>
        </CSidebarNav>

        <!-- <CSidebarToggler class="d-none d-lg-flex" @click="toggleUnfoldable" /> -->
    </CSidebar>
</template>

<script setup>
    import { cilHospital } from '@coreui/icons';
    import { useStore } from 'vuex';
    import { ref, reactive, computed, watch } from 'vue';
    import { useRoute, useRouter } from "vue-router";
    

    const store = useStore();
    const route = useRoute();

    const sidebarUnfoldable = computed(() => store.state.sidebarUnfoldable);
    const sidebarVisible    = computed(() => store.state.sidebarVisible);

    const clinicName = computed(() => {
        return store.getters['auth/clinicName'];
    });

    const menuList = computed(() => {
        return store.getters['router/menuList']();
    });

    const toggleUnfoldable = () => {
        store.commit('toggleUnfoldable');
    };

    const toggleSidebar = (value) => {
        if (sidebarVisible.value != value)
            store.commit('toggleSidebar');
    };

    watch(() => menuList, (list) => {
        console.log(list);
    });
</script>