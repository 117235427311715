import layout from '@/layout/index.vue';

export default {
	path: '/report',
	component: layout,
	meta: {
		title: '報表',
		category: 'account',
		icon: 'cilChart',
		allow: 7,
	},
	children: [
		{
			path: '/report',
			name: 'report',
			meta: {
				title: '統計報表',
				icon: 'cilChart',
				allow: 7,
			},
			component: () => import('@/views/report/report.vue')
		}
	]
}