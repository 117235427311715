import layout from '@/layout/index.vue';

export default {
  path: '/settingBulletin',
  component: layout,
  meta: {
    title: '系統公告',
    category: 'setting',
    icon: 'cilBullhorn',
    allow: 1,
  },
  children: [
    {
      path: '/settingBulletin',
      name: 'settingBulletin',
      meta: {
        title: '系統公告管理',
        icon: 'cilBullhorn',
        allow: 1,
      },
      component: () => import('@/views/settingBulletin/settingBulletin.vue')
    },
    {
      path: '/settingBulletinForm/:id',
      name: 'settingBulletinForm',
      meta: {
        title: '編輯系統公告',
        icon: 'cilBullhorn',
        allow: 1,
        hide: true
      },
      component: () => import('@/views/settingBulletin/settingBulletinForm.vue')
    }
  ]
}
