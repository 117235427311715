import layout from '@/layout/index.vue';

export default {
  path: '/clinic',
  component: layout,
  meta: {
    title: '治療所',
    category: 'dyAdmin',
    icon: 'cilHospital',
    allow: 1,
  },
  children: [
    {
      path: '/clinic',
      name: 'clinic',
      meta: {
        title: '治療所管理',
        icon: 'cilHospital',
        allow: 1,
      },
      component: () => import('@/views/clinic/clinic.vue')
    },
    {
      path: '/clinicForm/:id',
      name: 'clinicForm',
      meta: {
        title: '編輯治療所',
        icon: 'cilHospital',
        allow: 1,
        hide: true
      },
      component: () => import('@/views/clinic/clinicForm.vue')
    }
  ]
}
