import request from '@/utils/request';

export function content(params) {
    return request({
        url: '/book/content',
        method: 'get',
        params
    });
}

export function field(params) {
    return request({
        url: '/book/field',
        method: 'get',
        params
    });
}

export function questionnaire(params) {
    return request({
        url: '/book/questionnaire',
        method: 'get',
        params
    });
}

export function store({ id, data }) {
    return request({
        url: `/book/store/${id}`,
        method: 'post',
        data
    });
}
