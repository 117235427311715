import layout from '@/layout/index.vue';

export default {
	path: '/case',
	component: layout,
	meta: {
		title: '個案',
		// category: 'resource',
		category: 'course',
		icon: 'cilAddressBook',
		allow: 7,
	},
	children: [
		{
			path: '/case',
			name: 'case',
			meta: {
				title: '個案管理',
				icon: 'cilAddressBook',
				allow: 7,
			},
			component: () => import('@/views/case/case.vue')
		},
		{
			path: '/caseDetail/:id',
			name: 'caseDetail',
			meta: {
				title: '個案資料',
				icon: 'cilAddressBook',
				allow: 7,
				hide: true
			},
			component: () => import('@/views/case/caseDetail.vue')
		},
		{
			path: '/caseForm/:id',
			name: 'caseForm',
			meta: {
				title: '編輯個案',
				icon: 'cilAddressBook',
				allow: 7,
				hide: true
			},
			component: () => import('@/views/case/caseForm.vue')
		}
	]
}