import request from '@/utils/request';

export function login(data) {
    return request({
        url: '/auth/login',
        method: 'post',
        data,
    });
}

export function info(token) {
    return request({
        url: '/auth/info',
        method: 'get',
    });
}

export function refresh() {
    return request({
        url: '/auth/refresh',
        method: 'post'
    });
}

export function logout() {
    return request({
        url: '/auth/logout',
        method: 'delete',
    });
}

export function password(data) {
    return request({
        url: '/auth/password',
        method: 'post',
        data
    });
}

export function reportPassword(data) {
    return request({
        url: '/auth/reportPassword',
        method: 'post',
        data
    });
}

export function checkReportPassword(data) {
    return request({
        url: '/auth/checkReportPassword',
        method: 'post',
        data
    });
}

export function forget(data) {
    return request({
        url: '/auth/forget',
        method: 'post',
        data
    });
}

// 添加 Google 登入相關的 API 請求
export function googleLogin(token) {
    return request({
        url: '/auth/google',
        method: 'post',
        data: { token }
    });
}

// 可選：添加解除 Google 連接的方法
export function disconnectGoogle() {
    return request({
        url: '/auth/google/disconnect',
        method: 'post'
    });
}
