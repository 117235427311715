<template>
    <CHeader class="mb-4">
        <CContainer fluid>
            <div class="d-flex align-items-center">
                <CHeaderToggler class="ps-1" @click="toggleSidebar">
                    <CIcon icon="cil-menu" size="lg" />
                </CHeaderToggler>

                <div class="d-none d-lg-block">
                    <headerBreadcrumb  />
                </div>
            </div>
            
            
            <CHeaderBrand class="mx-auto d-lg-none" to="/">
                <div class="headerClinic fw-700">{{ clinicName }}</div>
            </CHeaderBrand>
            <CHeaderNav>
                <!-- <CNavItem>
                    <CNavLink href="#">
                        <CIcon class="mx-2" icon="cil-bell" size="lg" />
                    </CNavLink>
                </CNavItem> -->
                <CNavItem class="d-none d-lg-block">
                    <CNavLink href="https://could-helper.gitbook.io/liao-yu-yun-zhu-shou-jiao-xue-shou-ce" target="_blank">
                        <CIcon class="mx-2" icon="cil-book" size="lg" />
                    </CNavLink>
                </CNavItem> 
                <CNavItem class="d-none d-lg-block">
                    <CNavLink href="mailto:info@f16.one" target="_blank">
                        <CIcon class="mx-2" icon="cil-envelope-closed" size="lg" /> 
                    </CNavLink>
                </CNavItem>
                <CDropdown>
                    <CDropdownToggle placement="bottom-end" class="py-0 headerDropdownBtn" :caret="false">
                        <CIcon icon="cilUser" size="lg" />
                    </CDropdownToggle>
                    <CDropdownMenu class="pt-0">
                        <CDropdownHeader component="h6" class="bg-light fw-semibold py-2">
                            {{ userName }}
                        </CDropdownHeader>
                        <CDropdownItem @click="openModal('clinicModal')">
                            <CIcon icon="cil-settings" />
                            切換治療所
                        </CDropdownItem>
                        <CDropdownItem @click="logout">
                            <CIcon icon="cil-lock-locked" />
                            登出
                        </CDropdownItem>
                    </CDropdownMenu>
                </CDropdown>
            </CHeaderNav>
        </CContainer>
        <!-- <CHeaderDivider />
        <CContainer fluid>
            <headerBreadcrumb />
        </CContainer> -->
    </CHeader>

   <!-- 固定底部的 Footer 選單 -->
   <CFooter class="footer-menu d-lg-none">
        <CContainer fluid class="d-flex justify-content-around align-items-center">
            <router-link to="/order" class="footer-item">
                <img src="../../../images/icon-event.png" alt="處理預約" style="width: 24px; height: 24px;" />
                <div>處理預約</div>
            </router-link>
            
            <router-link to="/showAppointment" class="footer-item">
                <img src="../../../images/icon-calendar-plus.png" alt="建立預約" style="width: 24px; height: 24px;" />
                <div>建立預約</div>
            </router-link>
            
            <router-link to="/case" class="footer-item">
                <img src="../../../images/icon-search.png" alt="個案查詢" style="width: 24px; height: 24px;" />
                <div>個案查詢</div>
            </router-link>
            
            <router-link to="/" class="footer-item position-relative" @click.native="hasNewNotification = false">
                <img src="../../../images/icon-notification.png" alt="提醒" style="width: 24px; height: 24px;" />
                <div>提醒</div>
                <span v-if="hasNewNotification" class="notification-dot"></span>
            </router-link>
        </CContainer>
    </CFooter>


    <clinicModal v-model="clinicModalSetting" @closeModal="closeModal" />
    <!-- <orderNotifyModal v-model="orderNotifyModalSetting" @closeModal="closeModal"/> -->
</template>

<script setup>
    import headerBreadcrumb from '@/components/breadcrumb/headerBreadcrumb.vue';
    import clinicModal from '@/components/modal/clinicModal.vue';
    import orderNotifyModal from '@/components/modal/orderNotifyModal.vue';
    import { useStore } from 'vuex';
    import { ref, reactive, onMounted, computed , onUnmounted } from 'vue';
    import { useRoute, useRouter } from "vue-router";

    const store = useStore();
    const route 	= useRoute();
    const router = useRouter();

    // const clinicName         = ref(null);
    const clinicModalSetting = reactive({
        open: 0
    });

    const orderNotifyModalSetting = reactive({
        open: 0
    });

    const userName = computed(() => {
        return store.state.auth.user ? store.state.auth.user.name || null : null;
    });

    const clinicName = computed(() => {
        return store.getters['auth/clinicName'];
    });

    const toggleSidebar = () => {
        store.commit('toggleSidebar');
    };

    const logout = () => {
        store.dispatch('auth/logout').then(() => location.reload());
    };

    const openModal = (name) => {
        switch(name) {
            case 'clinicModal':
                clinicModalSetting.open++;
                break;
            case 'orderNotifyModal':
                orderNotifyModalSetting.open++;
                break;
        }
    };

    const closeModal = (name) => {
        switch(name) {
            case 'clinicModal':
                clinicModalSetting.open = 0;
                break;
            case 'orderNotifyModal':
                orderNotifyModalSetting.open = 0;
                break;
        }
    };

    // 模擬有新通知的條件
    const hasNewNotification = ref(true); // 當有新通知時變為 true



    onMounted(() => {
        if (store.state.auth.user.notify) {
            openModal('orderNotifyModal');
        }
    });   
</script>


<style scoped>
.footer-menu {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #1c3567e5; /* 深藍背景 */
    padding: 10px 0;
    z-index: 1000;
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .footer-item {
    text-align: center;
    color: white;
    flex-basis: 25%; 
    padding: 10px;
    font-size: 14px;
    transition: transform 0.4s ease-in-out; 
    font-size: 12px;
    margin-top: 5px;
  }
  .nav-link.footer-item:hover, .nav-link:focus{
    color: #fff;
  }
  
  /* 點擊按鈕縮放效果 */
  .footer-item:active {
    transform: scale(0.25); 
    background-color: rgba(255, 255, 255, 0.398); /* 點擊後有個輕微背景變化 */
    border-radius: 8px;
    color: #fff;
    transition: transform 0.4s ease-in-out; /* 點擊動畫時間延長 */
  }
  

  .footer-item div {
    margin-top: 5px;
  }
  
  .notification-dot {
    position: absolute;
    top: 0;
    left: 55%;
    width: 10px;
    height: 10px;
    background-color: red;
    border-radius: 50%;
  }
  
  @media (max-width: 768px) {
    .footer-menu {
      padding: 5px 0;
    }
  }
  
</style>