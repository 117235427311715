import request from '@/utils/request';

class resource {
    constructor(uri) {
        this.uri = uri;
    }
    index(params) {
        // console.log("API Params:", params); // 打印查詢參數
        return request({
            url: `/${this.uri}`,
            method: 'get',
            params,
        });
    }
    show(id) {
        return request({
            url: `/${this.uri}/${id}`,
            method: 'get',
        });
    }
    store(data) {
        return request({
            url: `/${this.uri}`,
            method: 'post',
            data
        });
    }
    insertCycle(data) {
        return request({
            url: `/${this.uri}/insertCycle`,
            method: 'post',
            data
        });
    }
    update({ id, data }) {
        return request({
            url: `/${this.uri}/${id}`,
            method: 'put',
            data
        });
    }
    destroy(id) {
        return request({
            url: `/${this.uri}/${id}`,
            method: 'delete',
        })
    }
    status({ id, data }) {
        return request({
            url: `/${this.uri}/status/${id}`,
            method: 'put',
            data
        });
    }
    select() {
        return request({
            url: `/${this.uri}/select`,
            method: 'get'
        });
    }
    direct(params) {
        return request({
            url: `/${this.uri}/direct`,
            method: 'get',
            params
        });
    }
    filter(params) {
        return request({
            url: `/${this.uri}/filter`,
            method: 'get',
            params
        });
    }
    reserve(params) {
        return request({
            url: `/${this.uri}/reserve`,
            method: 'post',
            params
        });
    }
    copy(params) {
        return request({
            url: `/${this.uri}/copy`,
            method: 'post',
            params
        });
    }
    unComment() {
        return request({
            url: `/${this.uri}/unComment`,
            method: 'get',
        });
    }
    diagnosis() {
        return request({
            url: `/${this.uri}/diagnosis`,
            method: 'get',
        });
    }
    comprehensive() {
        return request({
            url: `/${this.uri}/comprehensive`,
            method: 'get',
        });
    }
    reminder({ id, data }) {
        return request({
            url: `/${this.uri}/reminder/${id}`,
            method: 'put',
            data
        });
    }
}

export default resource;