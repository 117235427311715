import layout from '@/layout/index.vue';

export default {
  path: '/admin',
  component: layout,
  meta: {
    category: 'system',
    allow: 1,
  },
  children: [
    {
      path: '/admin',
      name: 'admin',
      meta: {
        title: '使用者管理',
        icon: 'cilSettings',
        allow: 1,
      },
      component: () => import('@/views/admin/admin.vue')
    },
    {
      path: '/adminForm/:id',
      name: 'adminForm',
      meta: {
        title: '編輯使用者',
        icon: 'cilSettings',
        allow: 1,
        hide: true
      },
      component: () => import('@/views/admin/adminForm.vue')
    }
  ]
}
