<template>
    <CBreadcrumb class="d-md-down-none me-auto mb-0 fs-7">
        <CBreadcrumbItem href="#/" :active="router.currentRoute.value.fullPath == '/'">
            首頁
        </CBreadcrumbItem>
        <CBreadcrumbItem v-for="item in breadcrumbs" :key="item" :href="item.active ? '' : item.path"
            :active="item.active">
            {{ item.name }}
        </CBreadcrumbItem>
    </CBreadcrumb>
</template>

<script setup>
    import { useStore } from 'vuex';
    import { ref, onMounted } from 'vue';
    import { useRouter } from "vue-router";

    const store     = useStore();
    const router    = useRouter();

    const breadcrumbs = ref([])

    const getBreadcrumbs = () => {
        return router.currentRoute.value.matched.filter((itme) => {
            return itme.path != '/' && itme.name;
        }).map((item, index) => {
            return {
                active: item.path === router.currentRoute.value.fullPath,
                path: index == 0 ? '' : `#${item.path}`,
                name: item.meta.title,
                icon: item.meta.icon,
            }
        });
    }

    router.afterEach(() => {
        breadcrumbs.value = getBreadcrumbs();
    });

    onMounted(() => {
        breadcrumbs.value = getBreadcrumbs();
    });

</script>
