import Cookies from 'js-cookie'

const clinicIdKey = 'clinicId';

function _testStorage() {
	if (typeof window == 'undefined')
		return false;
	if (typeof window.sessionStorage == 'undefined')
		return false;

	return true;
}

export function getClinicId() {
	if (!_testStorage())
		return null;

	return sessionStorage.getItem(clinicIdKey)
}

export function setClinicId(newClinicId) {
  	if (!_testStorage())
		return null;

  	return sessionStorage.setItem(clinicIdKey, newClinicId);
}

export function removeClinicId() {
	if (!_testStorage())
		return null;

 	return sessionStorage.removeItem(clinicIdKey)
}