import request from '@/utils/request';

export function content(params) {
    return request({
        url: '/book/survey/content',
        method: 'get',
        params
    });
}

export function store(data) {
    return request({
        url: `/book/survey/store`,
        method: 'post',
        data
    });
}
