import { login, logout, info, refresh, password, reportPassword, checkReportPassword, forget, googleLogin, disconnectGoogle } from '@/api/auth';
import { setToken, getToken, removeToken, setExpires } from "utils/auth";
import { setClinicId, getClinicId, removeClinicId } from "utils/clinicId";

export default {
    namespaced: true,
	state: {
		user: null,
		roles: [],
        clinic_id: null,
        isGoogleConnected: false,
        googleAccessToken: null
  	},
    getters: {
        clinicName: (state, getters, rootState, rootGetters) => {
            let clinicId = state.clinic_id;
            if (clinicId == 'all')
                return '全部';
            
            let clinicData = rootState.select.clinic.list.find((item) => item.id == clinicId);
            return clinicData ? clinicData.name : null;
        },
    },
    mutations: {
        setUser(state, data) {
            state.user = data || null;
        },
        setRoles(state, data) {
            state.roles = data || [];
        },
        setClinicId(state, data) {
            state.clinic_id = data || null;
            if (state.clinic_id && state.clinic_id != 'all')
                setClinicId(state.clinic_id);
            else
                removeClinicId(state.clinic_id);
        },
        setDefaultClinicId(state, data) {
            let newData = getClinicId();
            if (Number(newData) == 'NAN')
                newData = null;

            if (data.type == 1) {
                if (!newData)
                    newData = 'all';
            }

            if (data.type == 2) {
                if (newData != data.id)
                    newData = data.id;
            }

            if (data.type == 4) {
                if (data.clinic_id.length == 0)
                    newData = 'no';
                
                if (data.clinic_id.length > 0 && data.clinic_id.indexOf(Number(newData)) < 0)
                    newData = data.clinic_id[0];
            }

            state.clinic_id = newData;
            setClinicId(state.clinic_id);
        },
        setGoogleConnection(state, status) {
            state.isGoogleConnected = status;
        },
        setGoogleAccessToken(state, token) {
            state.googleAccessToken = token;
        },
    },
    actions: {
        login({}, loginForm) {
            return new Promise((resolve, reject) => {
                login(loginForm).then(response => {
                    if (response.status) {
                        if (response.data.access_token) {
                            setToken(response.data.access_token);
                            setExpires();
                        }
                    }

                    resolve(response);
                }).catch(error => {
                    reject(error);
                });
            });
        },
        info({ commit }) {
            return new Promise((resolve, reject) => {
                info().then(response => {
                    if (response.status) {
                        if (response.data)
                            commit('setUser', response.data);
                        if (response.data)
                            commit('setDefaultClinicId', response.data);
                        if (response.data.roles)
                            commit('setRoles', response.data.roles);
                    }
                    resolve(response)
                }).catch(error => {
                    reject(error);
                })
            })
        },
        logout({ commit }) {
            return new Promise((resolve, reject) => {
                logout().then(() => {
                    removeToken();
                    commit('setUser', null);
                    commit('setRoles', []);
                    resolve();
                }).catch(error => {
                    reject(error)
                })
            })
        },
        refresh() {
            return new Promise((resolve, reject) => {
                refresh().then(response => {
                    if (response.status) {
                        if (response.data.access_token) {
                            setToken(response.data.access_token);
                            setExpires();
                        }
                    }
                    resolve(response.status);
                }).catch(error => {
                    reject(error);
                })
            })
        },
        changeClinicId({ commit }, clinicId) {
            return new Promise(resolve => {
                commit('setClinicId', clinicId);
                resolve(true);
            })
        },
        password({ commit }, data) {
            return new Promise((resolve, reject) => {
                password(data).then(response => {
                    resolve(response.status);
                }).catch(error => {
                    reject(error);
                });
            });
        },
        reportPassword({ commit }, data) {
            return new Promise((resolve, reject) => {
                reportPassword(data).then(response => {
                    resolve(response.status);
                }).catch(error => {
                    reject(error);
                });
            });
        },
        checkReportPassword({ commit }, data) {
            return new Promise((resolve, reject) => {
                checkReportPassword(data).then(response => {
                    resolve(response.status);
                }).catch(error => {
                    reject(error);
                });
            });
        },
        forget({ commit }, data) {
            return new Promise((resolve, reject) => {
                forget(data).then(response => {
                    resolve(response.status);
                }).catch(error => {
                    reject(error);
                });
            });
        },
        async handleGoogleLogin({ commit }, token) {
            try {
                const response = await googleLogin(token);
                if (response.status) {
                    commit('setUser', response.data);
                    commit('setGoogleConnection', true);
                    return { status: true };
                }
                return { status: false };
            } catch (error) {
                console.error('Google 登入錯誤:', error);
                return { status: false };
            }
        },

        async handleGoogleDisconnect({ commit }) {
            try {
                const response = await disconnectGoogle();
                if (response.status) {
                    commit('setGoogleConnection', false);
                    return { status: true };
                }
                return { status: false };
            } catch (error) {
                console.error('解除 Google 連接錯誤:', error);
                return { status: false };
            }
        }
    }
}
