<template>
    <div class="pageLoading">
        <div class="spinner">
            <div class="cube1"></div>
            <div class="cube2"></div>
        </div>
    </div>
</template>

<script setup>
</script>
