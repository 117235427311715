import layout from '@/layout/index.vue';

export default {
  path: '/',
  component: layout,
  meta: {
    allow: 7
  },
  children: [
    {
      path: '/',
      name: 'Home',
      meta: {
        title: '首頁',
        icon: 'cilHome',
        allow: 7,
      },
      component: () => import('@/views/home.vue')
    }
  ]
}
