import resource from '@/api/resource.js';
const api = new resource('receipt');

export default {
    namespaced: true,
    state: {
        info: {},
        selectData: {
        },
    },
    getters: {
    },
    mutations: {
        setInfo: (state, { name, data }) => {
            state['info'] = data || {};
        },
    },
    actions: {
        getInfo({ state, commit }, id) {
            return new Promise((resolve, reject) => {
                api.show(id).then(response => {
                    if (response.status) {
                        commit('setInfo', { name: 'receipt', data: response.data });
                    }
                    resolve(response);
                }).catch(error => {
                    reject(error);
                });
            });
        },
        update({ commit }, { id, data }) {
            return new Promise((resolve, reject) => {
                api.update({ id, data }).then(response => {
                    resolve(response.status);
                }).catch(error => {
                    reject(error);
                });
            });
        },
    }
}