import layout from '@/layout/index.vue';

export default {
  path: '/option',
  component: layout,
  meta: {
    title: '項目',
    // category: 'system',
    category: 'stOwner',
    icon: 'cilListRich',
    allow: 3,
  },
  children: [
    {
      path: '/option',
      name: 'option',
      meta: {
        title: '項目管理',
        icon: 'cilListRich',
        allow: 3,
      },
      component: () => import('@/views/option/option.vue')
    }
  ]
}
