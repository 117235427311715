import { store, destroy } from '@/api/therapistBind';

export default {
    namespaced: true,
    state: {
    },
    getters: {
    },
    mutations: {
    },
    actions: {
        insert({}, data) {
            return new Promise((resolve, reject) => {
                store(data).then(response => {
                    resolve(response.status);
                }).catch(error => {
                    reject(error);
                });
            });
        },
        delete({ commit }, id) {
            return new Promise((resolve, reject) => {
                destroy(id).then(response => {
                    resolve(response.status);
                }).catch(error => {
                    reject(error);
                });
            });
        }
    }
}
