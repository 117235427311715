import layout from '@/layout/index.vue';

export default {
	path: '/course',
	component: layout,
	meta: {
		title: '課程',
		category: 'resource',
		icon: 'cilBook',
		allow: 3,
	},
	children: [
		{
			path: '/course',
			name: 'course',
			meta: {
				title: '服務項目',
				icon: 'cilBook',
				allow: 3,
			},
			component: () => import('@/views/course/course.vue')
		},
		{
			path: '/courseForm/:id',
			name: 'courseForm',
			meta: {
				title: '編輯服務',
				icon: 'cilBook',
				allow: 3,
				hide: true
			},
			component: () => import('@/views/course/courseForm.vue')
		}
	]
}