import resource from '@/api/resource.js';
const api = new resource('mailSetting');

export default {
    namespaced: true,
    state: {
        info: {},
        list: [],
        direct: [],
        tableSetting: {
            loading: true,
            searching: false,
            pageSetting: {
                total: 0,
                current: 1,
                pageSize: 10
            }
        },
        selectData: {
            therapist_id: null
        },
    },
    getters: {
    },
    mutations: {
        setList: (state, { name, data }) => {
            if (Object.keys(data).indexOf('list') >= 0 && data.list.length > 0) {
                data.list.forEach(function(item, index) {
                    data.list[index].loading = false;
                });
            }

            state['list'] = data.list || [];
            state['tableSetting'].pageSetting.total = data.total || 0;
        },
        setDirect: (state, { name, data }) => {
            state['direct'] = data.list || [];
        },
        setInfo: (state, { name, data }) => {
            state['info'] = data || {};
        },
        setLoading: (state, { name, data }) => {
            state['tableSetting'].loading     = data;
            state['tableSetting'].searching   = data;
        },
        setTableLoading: (state, { name, index, data }) => {
            state['list'][index].loading = data;
        },
        setPageSetting: (state, { name, type, data }) => {
            state['tableSetting'].pageSetting[type] = data;
        },
        setSelectData: (state, { name, field, data }) => {
            state['selectData'][field] = data;
        }
    },
    actions: {
        getList({ state, commit }) {
            return new Promise((resolve, reject) => {
                let selectFrom  = state.selectData
                let pageSetting = state.tableSetting.pageSetting
                api.index({ selectFrom, pageSetting }).then(response => {
                    if (response.status) {
                        commit('setList', { name: 'mailSetting', data: response.data });
                        commit('setLoading', { name: 'mailSetting', data: false });
                    }
                    resolve(response);
                }).catch(error => {
                    reject(error);
                });
            });
        },
        getInfo({ state, commit }, id) {
            return new Promise((resolve, reject) => {
                api.show(id).then(response => {
                    if (response.status) {
                        commit('setInfo', { name: 'mailSetting', data: response.data });
                    }
                    resolve(response);
                }).catch(error => {
                    reject(error);
                });
            });
        },
        insert({}, data) {
            return new Promise((resolve, reject) => {
                api.store(data).then(response => {
                    resolve(response.status);
                }).catch(error => {
                    reject(error);
                });
            });
        },
        update({ commit }, { id, data }) {
            return new Promise((resolve, reject) => {
                api.update({ id, data }).then(response => {
                    resolve(response.status);
                }).catch(error => {
                    reject(error);
                });
            });
        },
        delete({ commit }, id) {
            return new Promise((resolve, reject) => {
                api.destroy(id).then(response => {
                    resolve(response.status);
                }).catch(error => {
                    reject(error);
                });
            });
        },
        status({ commit }, { id, data }) {
            return new Promise((resolve, reject) => {
                api.status({ id, data }).then(response => {
                    resolve(response.status);
                }).catch(error => {
                    reject(error);
                });
            });
        },
        select({ rootState, commit}) {
            return new Promise((resolve, reject) => {
                if (rootState.select.mailSetting.need)
                    resolve(true);

                api.select().then(response => {
                    if (response.status) {
                        commit('select/setList', { name: 'mailSetting', data: response.data }, { root: true });
                    }
                    resolve(response.status);
                }).catch(error => {
                    reject(error);
                });
            });
        },
        direct({ rootState, commit}, { id, data }) {
            return new Promise((resolve, reject) => {
                api.direct({ id, params: data }).then(response => {
                    if (response.status) {
                        commit('setDirect', { name: 'mailSetting', data: response.data });
                    }
                    resolve(response.status);
                }).catch(error => {
                    reject(error);
                });
            });
        }
    }
}
